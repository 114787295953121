import Button from '@mui/material/Button';
import Page from 'material-ui-shell/lib/containers/Page/Page';
import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from 'react';
import Scrollbar from 'material-ui-shell/lib/components/Scrollbar/Scrollbar';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { createClient } from '@supabase/supabase-js';
import Alert from '@mui/material/Alert';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

const supabase = createClient(supabaseUrl, supabaseKey);

const defaultPath = 'asnanic';

const SupabaseUpload = () => {
  const intl = useIntl();
  const [path, setPath] = useState(defaultPath);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [downloadURL, setDownloadURL] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  useEffect(() => {
    const signIn = async () => {
      const { error } = await supabase.auth.signInWithPassword({
        email: 'manniru@gmail.com',
        password: '123456',
      });

      if (error) {
        console.error('Error signing in:', error.message);
      }
    };

    signIn();
  }, []);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setIsUploading(true);
      setUploadError(null);
      setUploadProgress(0);
      setUploadSuccess(false);

      const { data, error } = await supabase.storage
        .from('myiptv')
        .upload(`${path}/${file.name}`, file, {
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(progress);
          },
        });

      if (error) {
        setUploadError(error.message);
      } else {
        const { publicURL, error: urlError } = supabase.storage
          .from('myiptv')
          .getPublicUrl(`${path}/${file.name}`);
        if (urlError) {
          setUploadError(urlError.message);
        } else {
          setDownloadURL(publicURL);
          setUploadSuccess(true);
        }
      }
      setIsUploading(false);
    }
  };

  return (
    <Page
      pageTitle='Supabase Paths Demo'
    >
      <Scrollbar
        style={{ height: '100%', width: '100%', display: 'flex', flex: 1 }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Paper
            style={{
              maxWidth: 450,
              minWidth: 300,
              minHeight: 300,
              padding: 18,
            }}
          >
            <TextField
              label="Path"
              value={path}
              onChange={(e) => setPath(e.target.value)}
              variant="outlined"
            />
            <br />
            <br />
            {isUploading && (
              <Box position="relative" display="inline-flex">
                <CircularProgress variant="static" value={uploadProgress} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >{`${Math.round(uploadProgress)}%`}</Typography>
                </Box>
              </Box>
            )}
            <br />
            <br />
            {downloadURL && (
              <a href={downloadURL} target="_blank" rel="noopener noreferrer">
                Download File
              </a>
            )}
            <br />
            <br />
            {uploadSuccess && (
              <Alert severity="success">
                File uploaded successfully!
              </Alert>
            )}
            <br />
            {uploadError && (
              <Typography variant="subtitle1" color="error">
                Error: {uploadError}
              </Typography>
            )}
            <br />
            <br />
            <div>
              <input
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button
                  style={{ margin: 5 }}
                  variant="contained"
                  color="primary"
                  component="span"
                >
                  UPLOAD
                </Button>
              </label>
            </div>
          </Paper>
        </div>
      </Scrollbar>
    </Page>
  );
};

export default SupabaseUpload;
