/* eslint-disable react/jsx-key */
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute'
import React, { lazy } from 'react'

// mannir
import DemosPage from 'qrpay/DemosPage'
import Info from 'qrpay/Info'
import ReceivePage from 'qrpay/ReceivePage'
import SendPage from 'qrpay/SendPage'
import TransferPage from 'qrpay/TransferPage'
import WithdrawPage from 'qrpay/WithdrawPage'
import SupabaseRealtime from 'esys/SupabaseRealtime'
import SupabaseOffline from 'esys/SupabaseOffline'
import SupabaseUpload from 'esys/SupabaseUpload'
import SupabaseUploads from 'esys/SupabaseUploads'
import SupabaseAuth from 'esys/SupabaseAuth'
// import VideoPlayer from 'myiptv/VideoPlayer'
// import Sound1 from 'esys/Sound1'


const About = lazy(() => import('../pages/About'))
const Admin = lazy(() => import('../pages/Demo/Admin'))
const Companies = lazy(() => import('../pages/Demo/Companies'))
const Company = lazy(() => import('../pages/Demo/Companies/Company'))
const Tasks = lazy(() => import('../pages/Demo/Tasks'))
const Task = lazy(() => import('../pages/Demo/Tasks/Task'))
const FirebaseCols = lazy(() => import('../pages/Firebase/Cols'))
const FirebaseDocs = lazy(() => import('../pages/Firebase/Docs'))
const FirebaseLists = lazy(() => import('../pages/Firebase/Lists'))
const FirebaseMessaging = lazy(() => import('../pages/Firebase/Messaging'))
const FirebasePaths = lazy(() => import('../pages/Firebase/Paths'))
const FirebaseStorage = lazy(() => import('../pages/Firebase/Storage'))
const Dashboard = lazy(() => import('../pages/Dashboard'))

// mannir
const AccountPage = lazy(() => import('../qrpay/AccountPage'))
const BalancePage = lazy(() => import('../qrpay/BalancePage'))
const AIPage = lazy(() => import('../aboki/AIPage'))
const Sound1 = lazy(() => import('../esys/Sound1'))
const Page1 = lazy(() => import('../esys/Page1'))
const PlantForm = lazy(() => import('../esys/PlantForm'))
const Plants = lazy(() => import('../iplant/Plants'))
const DashboardPage = lazy(() => import('../esys/DashboardPage'))
const PlantPage = lazy(() => import('../iplant/Plant'))

// iptv
const LivePage = lazy(() => import('../myiptv/LivePage'))
const UploadPage = lazy(() => import('../myiptv/UploadPage'))
const ViewVideos = lazy(() => import('../myiptv/ViewVideos'))
const Demo1Page = lazy(() => import('../esys/Demo1Page'))
const MyAccount = lazy(() => import('../esys/MyAccount'))
const VODPage = lazy(() => import('../myiptv/MoviesPage'))

const Player1 = lazy(() => import('../myiptv/Player1'))
const Player2 = lazy(() => import('../myiptv/Player2'))
const CategoryPage = lazy(() => import('../myiptv/CategoryPage'))
const FreeStreamPage = lazy(() => import('../myiptv/FreeStreamPage'))
const PricingPage = lazy(() => import('../myiptv/PricingPage'))
const ContactPage = lazy(() => import('../myiptv/ContactPage'))
const SubscriptionPage = lazy(() => import('../myiptv/SubscriptionPage'))
const ArtistPage = lazy(() => import('../myiptv/ArtistPage'))
const SupabaseDemos = lazy(() => import('../esys/SupabaseDemos'))
const SupabaseStorage = lazy(() => import('../esys/supabase/SupabaseStorage'))
const PlayPage = lazy(() => import('../myiptv/PlayPage'))
const VideoPlayer = lazy(() => import('../myiptv/VideoPlayer'))

const LiveNgPage = lazy(() => import('../myiptv/LiveNgPage'))
const CategoriesPage = lazy(() => import('../myiptv/CategoriesPage'))
const LanguagesPage = lazy(() => import('../myiptv/LanguagesPage'))

const ELearningPage = lazy(() => import('../elearning/eLearningPage'))
const ChannelPage = lazy(() => import('../elearning/ChannelPage'))
const YoutubePlayer = lazy(() => import('../elearning/YoutubePlayer'))

const ForyouPage = lazy(() => import('../myiptv/ForyouPage'))


const routes = [
  {
    path: '/about',
    exact: true,
    element: <About />,
  },
  {
    path: '/account',
    exact: true,
    element: <AccountPage />,
  },
  {
    path: '/balance',
    exact: true,
    element: <BalancePage />,
  },
  {
    path: '/info1',
    exact: true,
    element: <Info />,
  },
  {
    path: '/send',
    exact: true,
    element: <SendPage />,
  },
  {
    path: '/receive',
    exact: true,
    element: <ReceivePage />,
  },
  {
    path: '/r/:wid',
    exact: true,
    element: <ReceivePage />,
  },
  {
    path: '/transfer',
    exact: true,
    element: <TransferPage />,
  },
  {
    path: '/withdraw',
    exact: true,
    element: <WithdrawPage />,
  },
  {
    path: '/demos',
    exact: true,
    element: <DemosPage />,
  },
  {
    path: '/ai',
    exact: true,
    element: <AIPage />,
  },
  {
    path: '/dashboard',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Dashboard />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_paths',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebasePaths />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_lists',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseLists />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_docs',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseDocs />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_cols',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseCols />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/admin',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Admin />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/companies',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Companies />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/companies/:uid',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Company />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/create_company',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Company />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/tasks',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Tasks />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/tasks/:uid',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Task />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/create_task',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Task />
      </AuthorizedRoute>
    ),
  },

  {
    path: '/firebase_messaging',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseMessaging />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_storage',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseStorage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/plant',
    exact: true,
    element: <AuthorizedRoute>
    <PlantPage />
  </AuthorizedRoute>,
  },
  {
    path: '/sound1',
    exact: true,
    element: <Sound1 />,
  },
  {
    path: '/page1',
    exact: true,
    element: <Page1 />,
  },
  {
    path: '/webnfc',
    exact: true,
    element: <Page1 />,
  },
  // {
  //   path: '/plant',
  //   exact: true,
  //   element: <PlantForm />,
  // },
  {
    path: '/plants',
    exact: true,
    element: <AuthorizedRoute>
    <Plants />
  </AuthorizedRoute>,
  },
  {
    path: '/home',
    exact: true,
    element: <DashboardPage />,
  },

  // iptv
  {
    path: '/live',
    exact: true,
    element: <LivePage />,
  },
  {
    path: '/upload',
    exact: true,
    element: <AuthorizedRoute><UploadPage /></AuthorizedRoute>,
  },
  {
    path: '/videos',
    exact: true,
    element: <AuthorizedRoute><ViewVideos /></AuthorizedRoute>,
  },
  {
    path: '/demo1',
    exact: true,
    element: <Demo1Page />,
  },
  {
    path: '/supabase1',
    exact: true,
    element: <SupabaseRealtime />,
  },
  {
    path: '/supabase2',
    exact: true,
    element: <SupabaseOffline />,
  },
  {
    path: '/supabase3',
    exact: true,
    element: <SupabaseUpload />,
  },
  {
    path: '/supabase4',
    exact: true,
    element: <SupabaseUploads />,
  },
  {
    path: '/supabase5',
    exact: true,
    element: <SupabaseAuth />,
  },
  {
    path: '/profile',
    exact: true,
    element: <MyAccount />,
  },
  {
    path: '/movies',
    exact: true,
    element: <VODPage />,
  },
  {
    path: '/player',
    exact: true,
    element: <VideoPlayer />,
  },
  {
    path: '/player1',
    exact: true,
    element: <Player1 />,
  },
  {
    path: '/player2',
    exact: true,
    element: <Player2 />,
  },
  {
    path: '/play/:id',
    exact: true,
    element: (
      <AuthorizedRoute>
        <PlayPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/free-stream',
    exact: true,
    element: <FreeStreamPage />,
  },
  {
    path: '/pricing',
    exact: true,
    element: <PricingPage />,
  },
  {
    path: '/contact',
    exact: true,
    element: <ContactPage />,
  },
  {
    path: '/subscription',
    exact: true,
    element: <SubscriptionPage />,
  },
  {
    path: '/category/:id',
    exact: true,
    element: (
        <CategoryPage />
    ),
  },
  {
    path: '/artist',
    exact: true,
    element: <ArtistPage />,
  },
  {
    path: '/supabasedemos',
    exact: true,
    element: <SupabaseDemos />,
  },
  {
    path: '/supabase/database',
    exact: true,
    element: <SupabaseDemos />,
  },
  {
    path: '/supabase/storage',
    exact: true,
    element: <SupabaseStorage />,
  },
  {
    path: '/live/ng',
    exact: true,
    element: <LiveNgPage />,
  },
  {
    path: '/categories/:id',
    exact: true,
    element: (
      <AuthorizedRoute>
        <CategoriesPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/languages/:id',
    exact: true,
    element: (
      <AuthorizedRoute>
        <LanguagesPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/elearning',
    exact: true,
    element: <ELearningPage />,
  },
  // {
  //   path: '/youtube',
  //   exact: true,
  //   element: <YoutubePage />,
  // },
  {
    path: '/channel/:id',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ChannelPage />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/youtube/:id',
    exact: true,
    element: (
      <AuthorizedRoute>
        <YoutubePlayer />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/foryou',
    exact: true,
    element: <ForyouPage />,
  },
]

export default routes
