import { usePaths } from 'rmw-shell/lib/providers/Firebase/Paths'
import { useTheme } from '@mui/material/styles'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'


import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Page from 'material-ui-shell/lib/containers/Page/Page'
import Paper from '@mui/material/Paper'
import React, { useState, useEffect } from 'react'
import Scrollbar from 'material-ui-shell/lib/components/Scrollbar/Scrollbar'
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'
import { useDocs } from 'rmw-shell/lib/providers/Firebase/Docs'
import { doc, setDoc, getFirestore } from 'firebase/firestore'
import { useAuth } from 'base-shell/lib/providers/Auth'
import { useQRCode } from 'next-qrcode';

import { QrReader } from 'react-qr-reader';

const t1 = {padding: 10}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const currentDate = new Date()
const currentYear = currentDate.getFullYear()
const daysPath = `/user_registrations_per_day/${currentYear}/${new Date()
  .toISOString()
  .slice(5, 7)}`

// eslint-disable-next-line
export default function () {
  
  const { auth } = useAuth()
  const { Canvas } = useQRCode();
  const phone = auth.email.replace('@qrpay.cloud','');
  const defaultPath = `_accounts/${phone}`
  const [data, setData] = useState('No result');
  const [path, setPath] = useState(defaultPath)
  const [amount, setAmount] = useState('')
  const {
    watchDoc,
    getDoc,
    clearDoc,
    getDocError,
    isDocLoading,
    hasDocError,
    unwatchDoc,
  } = useDocs()

  const account = getDoc(defaultPath, 'no value')

  const error = JSON.stringify(getDocError(path))
  const isLoading = isDocLoading(path)


  return (
    <div>
      <Page
      pageTitle="Send to Wallet"
    >
      <Scrollbar
        style={{ height: '100%', width: '100%', display: 'flex', flex: 1 }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Paper
            style={{
              maxWidth: 450,
              minWidth: 300,
              minHeight: 300,
              padding: 10,
            }}
          >
            <TextField
              label="Wallet Account Path"
              value={path}
              onChange={(e) => setPath(e.target.value)}
              variant="outlined"
              fullWidth
              style={t1}
            />
            <br />
            <TextField
              label="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              variant="outlined"
              fullWidth
              style={t1}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <br />
            {isLoading && <CircularProgress />}
            <br />
            {account.balance && <Typography variant="h6">Balance: &#x20A6; {account.balance.toLocaleString('en-NG')}</Typography>}
            <br />
                        
            
            {hasDocError(path) && (
              <Typography variant="subtitle1" color="error">
                Error: {error}
              </Typography>
            )}
            <br />
            <br />
            
          </Paper>
        </div>
      </Scrollbar>
    </Page>
    </div>
  )
}
