/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { useAuth } from 'base-shell/lib/providers/Auth'

export default function () {
  const { auth } = useAuth()
  const phone = auth.email.replace('@qrpay.cloud','');

  return (
    <div>
     {phone}
    </div>
  )
}
