import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
// console.log('REACT_APP_ENVIRONMENT => ', process.env.REACT_APP_ENVIRONMENT);

// console.log('process.env.REACT_APP_SUPABASE_URL: ', process.env.REACT_APP_SUPABASE_URL);
// console.log('process.env.REACT_APP_SUPABASE_KEY: ', process.env.REACT_APP_SUPABASE_KEY);
// console.log('process.env.REACT_APP_SERVER_IP: ', process.env.REACT_APP_SERVER_IP);


// Get the root element
const container = document.getElementById("root");

// Create a root
const root = createRoot(container);

// Initial render
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (reg) => {
    window.update = () => {
      try {
        reg.waiting.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
      } catch (error) {
        console.warn("error", error);
      }
    };
  },
});
