import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Page from 'material-ui-shell/lib/containers/Page/Page'
import Paper from '@mui/material/Paper'
import React, { useState, useEffect } from 'react'
import Scrollbar from 'material-ui-shell/lib/components/Scrollbar/Scrollbar'
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'
import { useDocs } from 'rmw-shell/lib/providers/Firebase/Docs'
import { doc, setDoc } from 'firebase/firestore'
import { useAuth } from 'base-shell/lib/providers/Auth'
import MenuItem from '@mui/material/MenuItem';
import { faker } from '@faker-js/faker';
import { useQRCode } from 'next-qrcode';
import { useParams } from 'react-router-dom'

const t1 = {padding: 10}


const ReceivePage = () => {
  const { auth } = useAuth()
  const { Canvas } = useQRCode();
  var { wid } = useParams()
  var phone = (wid) ? wid : auth.email.replace('@qrpay.cloud','');
  const defaultPath = `_accounts/${phone}`

  const [message, setMessage] = useState(null);


  const [path, setPath] = useState(defaultPath)
  const [amount, setAmount] = useState('')
  const {
    watchDoc,
    getDoc,
    clearDoc,
    getDocError,
    isDocLoading,
    hasDocError,
    unwatchDoc,
  } = useDocs()

  // const daysPath = `_accounts/2348033099431`

  // const databaseValue = JSON.stringify(getDoc(path, 'no value'))
  const account = getDoc(defaultPath, {
    name: '',
    phone: '',
    email: '',
    balance: 0,
    // transactions: [],
    // createdAt: new Date(),
    // updatedAt: new Date(),
  })

  const error = JSON.stringify(getDocError(path))
  const isLoading = isDocLoading(path)

  // mannir

  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    pass: '',
    bvn:'',
    bank: '',
    accno: '',
    accname: '',
    nin: '',
    balance: 1000000.75

  })


  // useEffect(() => {
  //   watchList('companies')

  //   return () => unwatchList('companies')
  // }, [watchList, unwatchList])


  // useEffect(() => {
  //   watchDoc('_accounts/2348033099431')
  //   return () => {
  //     unwatchDoc('_accounts/2348033099431')
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])


  /*
  useEffect(() => {
    try {
      watchDoc(defaultPath)
    } catch (error) {
      console.log(error)
    }
    return () => {
      try {
        unwatchDoc(defaultPath)
      } catch (error) {
        console.log(error)
      }
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  */

  // setValues({ ...values, balance: 1000000.75 })

useEffect(() => {
    // ✅ Check if NOT equal to null
    if (phone !== null) {
      console.log('variable is NOT null');
    }

    // ✅ Check if null
    if (phone === null) {
      console.log('variable is null');
    }

    // ✅ Check if value is NOT equal to null and undefined
    if (phone !== undefined && phone !== null) {
      console.log('variable is NOT undefined or null');
    }

    // ✅ Check if value is equal to null or undefined
    if (phone === undefined || phone === null) {
      console.log('variable is undefined or null');
    }

    
  }, [phone]);


  watchDoc(defaultPath)

  return (
    <Page
      pageTitle="Receive from Wallet"
    >
      <Scrollbar
        style={{ height: '100%', width: '100%', display: 'flex', flex: 1 }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Paper
            style={{
              maxWidth: 450,
              minWidth: 300,
              minHeight: 300,
              padding: 10,
            }}
          >

            {phone && <Typography variant="h6" style={{textAlign: "center"}}>Wallet Id: {phone}</Typography>}
            {account.balance && <Typography variant="h6" style={{textAlign: "center"}}>Balance: &#x20A6; {account.balance.toLocaleString('en-NG')}</Typography>}


            {isLoading && <CircularProgress />}
            <br />
            {/* {values.balance && <Typography variant="h6" style={{textAlign: "center"}}>Wallet Balance: &#x20A6; {values.balance.toLocaleString('en-NG')}</Typography>} */}

            {/* <TextField
              label="Wallet Account Path"
              value={path}
              onChange={(e) => setPath(e.target.value)}
              variant="outlined"
              fullWidth
              style={t1}
            />
            */}
            <br />
            <TextField
              label="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              variant="outlined"
              fullWidth
              style={t1}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            
            <div align="center">
              <Canvas
                text={`https://qrpay.cloud/r/234${phone.slice(3)}/${amount}`}
                options={{
                  type: 'image/jpeg',
                  quality: 0.3,
                  level: 'M',
                  margin: 3,
                  scale: 4,
                  width: 200,
                  color: {
                    dark: '#010599FF',
                    light: '#FFBF60FF',
                  },
                }}
              />
            </div>
            <br />
            {hasDocError(path) && (
              <Typography variant="subtitle1" color="error">
                Error: {error}
              </Typography>
            )}
            <br />
          
          </Paper>
        </div>
      </Scrollbar>
    </Page>
  )
}

export default ReceivePage
