import Button from '@mui/material/Button';
import Page from 'material-ui-shell/lib/containers/Page/Page';
import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from 'react';
import Scrollbar from 'material-ui-shell/lib/components/Scrollbar/Scrollbar';
import TextField from '@mui/material/TextField';
import axios from 'axios';

const defaultPath = 'mannir1';

const SupabaseOffline = () => {
  const [path, setPath] = useState(defaultPath);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    // Function to fetch records from the local Supabase instance
    const fetchRecords = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_SUPABASE_API_URL);
        setRecords(response.data);
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    fetchRecords();
  }, []);

  return (
    <Page pageTitle='Supabase Offline'>
      <Scrollbar
        style={{ height: '100%', width: '100%', display: 'flex', flex: 1 }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Paper
            style={{
              maxWidth: 450,
              minWidth: 300,
              minHeight: 300,
              padding: 18,
            }}
          >
            <TextField
              label="Name"
              value={path}
              onChange={(e) => setPath(e.target.value)}
              variant="outlined"
            />
            <br />
            <div>
              <label htmlFor="contained-button-file">
                <br />
                <Button
                  style={{ margin: 5 }}
                  variant="contained"
                  color="primary"
                  component="span"
                >
                  Submit
                </Button>
              </label>
            </div>
            <div>
              <h3>Records:</h3>
              <ul>
                {records.map((record) => (
                  <li key={record.id}>{JSON.stringify(record)}</li>
                ))}
              </ul>
            </div>
          </Paper>
        </div>
      </Scrollbar>
    </Page>
  );
};

export default SupabaseOffline;
