import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Page from 'material-ui-shell/lib/containers/Page/Page'
import Paper from '@mui/material/Paper'
import React, { useState, useEffect } from 'react'
import Scrollbar from 'material-ui-shell/lib/components/Scrollbar/Scrollbar'
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'
import { useDocs } from 'rmw-shell/lib/providers/Firebase/Docs'
import { doc, setDoc } from 'firebase/firestore'
import { useAuth } from 'base-shell/lib/providers/Auth'
import { useQRCode } from 'next-qrcode';
import { useNavigate } from 'react-router-dom'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { QrReader } from 'react-qr-reader';
import axios from 'axios'


import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularIntegration from './components/CircularIntegration'
import FormDialog from './components/FormDialog'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

// import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// const db = getFirestore();
// connectFirestoreEmulator(db, '192.168.0.133', 8080);

const t1 = {padding: 10}
const b1 = {padding: 10}

const CLOUD_FN_API = 'https://us-central1-myiptvng.cloudfunctions.net'
// const CLOUD_FN_API = 'http://0.0.0.0:5001/myiptvng/us-central1'


const SendPage = () => {
  const { auth } = useAuth()
  const { Canvas } = useQRCode();
  const phone = auth.email.replace('@qrpay.cloud','');
  const defaultPath = `_accounts/${phone}`
  const [data, setData] = useState('No result');
  const [path, setPath] = useState(defaultPath)
  const [values, setValues] = useState({from: '', to: '', amount: ''})
  const [pin, setPin] = useState('')

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    watchDoc,
    getDoc,
    clearDoc,
    getDocError,
    isDocLoading,
    hasDocError,
    unwatchDoc,
  } = useDocs()

  const account = getDoc(defaultPath, 'no value')

  const error = JSON.stringify(getDocError(path))
  const isLoading = isDocLoading(path)


  const [isLoading2, setLoading2] = useState(false)
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const handleClick = (e, id) => {
    switch (id) {
      case 'send':
        console.log(account)
        if (1 === 1) {
          if (!loading) {
            setSuccess(false);
            setLoading(true);
            var data = JSON.stringify({
              "from": phone,
              "to": values.to,
              "amount": values.amount
            });
            
            var config = {
              method: 'post',
              url: `${CLOUD_FN_API}/qrpay-send`,
              headers: { 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios(config)
            .then(function (response) {
              setSuccess(true);
              setLoading(false);
              setOpen(false);
              console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
              setSuccess(true);
              setLoading(false);
            });
        
            console.log(id)
  
          }
        } else {
          console.log('Wrong PIN')
        }
        
        
        break;

      case 'scan':
        setScan(id);

        break;
    
      default:
        break;
    }
  }

  const [isScan, setScan] = useState(false)


  const handleAlignment = (e, type) => {
    setScan(type);
  };

  const handleButtonClick2 = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  console.log(account)

  return (
    <Page
      pageTitle="Send to Wallet"
    >
      <Scrollbar
        style={{ height: '100%', width: '100%', display: 'flex', flex: 1 }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Paper
            style={{
              maxWidth: 450,
              minWidth: 300,
              minHeight: 300,
              padding: 10,
            }}
          >

            {isLoading && <CircularProgress />}
            <br />
            {phone && <Typography variant="h6" style={{textAlign: "center"}}>Wallet Id: {phone}</Typography>}
            {account.balance && <Typography variant="h6" style={{textAlign: "center"}}>Balance: &#x20A6; {account.balance.toLocaleString('en-NG')}</Typography>}


            {/* <TextField
              label="Wallet Account Path"
              value={path}
              onChange={(e) => setPath(e.target.value)}
              variant="outlined"
              fullWidth
              style={t1}
            /> */}
            <br />

            <TextField
              label="Send To"
              type="number"
              value={values.to}
              onChange={(e) => setValues({ ...values, to: e.target.value })}
              variant="outlined"
              fullWidth
              style={t1}
              // type="number"
              inputProps={{  minLength: 13, maxLength: 13}}
            />
            <br />

            <TextField
              label="Amount"
              value={values.amount}
              onChange={(e) => setValues({ ...values, amount: e.target.value })}
              variant="outlined"
              fullWidth
              style={t1}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            {isLoading2 && <CircularProgress />}
            {!isLoading2 && (
              <div>
              <Button variant="contained" color="success" onClick={handleClickOpen} style={{width: '100%'}}>
                Send Payment
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Enter PIN</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="pin"
                    onChange={(e) => setPin( e.target.value )}
                    label="PIN Code"
                    type="password"
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" color="warning" onClick={handleClose}>Cancel</Button>
                  {/* <Button variant="contained" color="success" onClick={e => handleClick(e, 'send')}>Submit</Button> */}
                  
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ m: 1, position: 'relative' }}>
                      <Button
                        variant="contained"
                        sx={buttonSx}
                        disabled={loading}
                        onClick={e => handleClick(e, 'send')}
                      >
                        Send
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                </DialogActions>
              </Dialog>
    
              {/* <CircularIntegration /> */}
              </div>

            )}
            <br />
          
            <div>
            <ToggleButtonGroup
              value={isScan}
              exclusive
              onChange={handleAlignment}
            >
             
              <ToggleButton value="scan" aria-label="centered">
              <Button variant="contained" color="primary" onClick={e => handleClick(e, 'scan')}>Scan QRCode</Button>
              </ToggleButton>
              
            </ToggleButtonGroup>
            </div>
            
            <div>

            {isScan && (<QrReader
              facingMode='user'
              constraints={{facingMode: 'environment'}}
              onResult={(result, error) => {
                if (!!result) {
                  var result_test = result?.text
                  
                  try {
                    var dt = result_test.split("/");
                    console.log(dt)
                    setValues({ ...values, to: dt[4], amount: dt[5] })
                  } catch (err) {
                    console.log(err)
                  }


                  setData(result_test);
                  setScan(false);
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              style={{ width: '100%' }}
            />)}

            </div>
            
            {hasDocError(path) && (
              <Typography variant="subtitle1" color="error">
                Error: {error}
              </Typography>
            )}
            <br />
            
          </Paper>
        </div>
      </Scrollbar>
    </Page>
  )
}

export default SendPage
