import React, { useState } from 'react'
import { createClient } from '@supabase/supabase-js'
import Page from 'material-ui-shell/lib/containers/Page/Page'
import Paper from '@mui/material/Paper'
import Scrollbar from 'material-ui-shell/lib/components/Scrollbar/Scrollbar'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase URL or Key. Please check your .env file.');
}

const supabase = createClient(supabaseUrl, supabaseKey);
const SupabaseAuth = () => {
  const [user, setUser] = useState(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSignUp, setIsSignUp] = useState(false)
  const [error, setError] = useState('')

  const handleSignUp = async () => {
    const { error, data } = await supabase.auth.signUp({
      email,
      password,
    })
    if (error) {
      setError(error.message)
    } else {
      setUser(data.user)
      setError('')
    }
  }

  const handleLogin = async () => {
    const { error, data } = await supabase.auth.signInWithPassword({
      email,
      password,
    })
    if (error) {
      setError(error.message)
    } else {
      setUser(data.user)
      setError('')
    }
  }

  const handleLogout = async () => {
    await supabase.auth.signOut()
    setUser(null)
  }

  return (
    <Page pageTitle={'Plant Page'}>
      <Scrollbar style={{ height: '100%', width: '100%', display: 'flex', flex: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Paper style={{ maxWidth: 450, minWidth: 300, minHeight: 300, padding: 18 }}>
            <div>
              {user ? (
                <>
                  <div>Welcome, {user.email}</div>
                  <Button variant="contained" color="primary" onClick={handleLogout}>
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <h2>{isSignUp ? 'Sign Up' : 'Login'}</h2>
                  {error && <div style={{ color: 'red' }}>{error}</div>}
                  <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={isSignUp ? handleSignUp : handleLogin}
                    fullWidth
                  >
                    {isSignUp ? 'Sign Up' : 'Login'}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setIsSignUp(!isSignUp)}
                    fullWidth
                  >
                    {isSignUp ? 'Have an account? Login' : 'Need an account? Sign Up'}
                  </Button>
                </>
              )}
            </div>
          </Paper>
        </div>
      </Scrollbar>
    </Page>
  )
}

export default SupabaseAuth
