import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button, CircularProgress } from "@mui/material";
import { createClient } from "@supabase/supabase-js";
import Alert from '@mui/material/Alert';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase URL or Key. Please check your .env file.');
}

const supabase = createClient(supabaseUrl, supabaseKey);

const SupabaseUploads = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoRefs, setVideoRefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setIsLoading(true);
        const { data, error } = await supabase.storage.from('myiptv').list('asnanic', {
          limit: 100,
          offset: 0,
          sortBy: { column: 'name', order: 'asc' },
        });

        if (error) {
          throw error;
        }

        const urls = await Promise.all(data.map(async (item) => {
          const { data: publicData, error: urlError } = supabase.storage.from('myiptv').getPublicUrl(`asnanic/${item.name}`);
          if (urlError) {
            throw urlError;
          }
          return publicData.publicUrl;
        }));

        setVideoUrls(urls);
        setVideoRefs(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const handleDelete = async (index) => {
    try {
      const videoRef = videoRefs[index];
      const { error } = await supabase.storage.from('myiptv').remove([`asnanic/${videoRef.name}`]);

      if (error) {
        setDeleteError(error.message);
        setDeleteSuccess(false);
        throw error;
      }

      setVideoUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
      setVideoRefs((prevRefs) => prevRefs.filter((_, i) => i !== index));
      setDeleteSuccess(true);
      setDeleteError(null);
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };

  return (
    <Box sx={{ p: 4, height: '80vh', overflow: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        View Videos
      </Typography>
      {isLoading && <CircularProgress />}
      {deleteSuccess && <Alert severity="success">Video deleted successfully!</Alert>}
      {deleteError && <Alert severity="error">{deleteError}</Alert>}
      <Grid container spacing={2}>
        {videoUrls.map((url, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <video
              width="100%"
              height="100%"
              controls
              controlsList="nodownload"
              style={{ maxWidth: '300px', maxHeight: '300px' }}
            >
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDelete(index)}
              style={{ marginTop: '10px' }}
            >
              Delete
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SupabaseUploads;
