import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'material-ui-shell/lib/containers/Page/Page';
import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from 'react';
import Scrollbar from 'material-ui-shell/lib/components/Scrollbar/Scrollbar';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

const supabase = createClient(supabaseUrl, supabaseKey);

const defaultPath = 'tests/1';

const SupabaseRealtime = () => {
  const intl = useIntl();
  const [path, setPath] = useState(defaultPath);
  const [value, setValue] = useState('');
  const [databaseValue, setDatabaseValue] = useState('no value');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('tests')
        .select('*')
        .eq('id', 1);
        
      if (error) {
        setError(error.message);
      } else {
        setDatabaseValue(JSON.stringify(data[0]));
      }
      setIsLoading(false);
    };

    fetchData();

    const channel = supabase
      .channel('public:tests')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'tests' }, payload => {
        console.log('Change received!', payload);
        setDatabaseValue(JSON.stringify(payload.new));
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const handleSet = async () => {
    console.log('Setting value:', value);
    const { data, error } = await supabase
      .from('tests')
      .upsert({ id: 1, value });

    if (error) {
      console.error('Error setting value:', error.message);
      setError(error.message);
    } else {
      console.log('Set value response:', data);
      setDatabaseValue(JSON.stringify(data));
    }
  };

  return (
    <Page
      pageTitle={intl.formatMessage({
        id: 'supabase_paths_demo',
        defaultMessage: 'Supabase Realtime',
      })}
    >
      <Scrollbar
        style={{ height: '100%', width: '100%', display: 'flex', flex: 1 }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Paper
            style={{
              maxWidth: 450,
              minWidth: 300,
              minHeight: 300,
              padding: 18,
            }}
          >
            <TextField
              label="Path"
              value={path}
              onChange={(e) => setPath(e.target.value)}
              variant="outlined"
            />
            <br />
            <br />
            {isLoading && <CircularProgress />}
            <br />
            <br />
            {databaseValue}
            <br />
            <br />
            {error && (
              <Typography variant="subtitle1" color="error">
                Error: {error}
              </Typography>
            )}
            <br />
            <br />
            <div>
              <Button
                style={{ margin: 5 }}
                variant="contained"
                color="primary"
                onClick={() => {}}
              >
                Watch
              </Button>
              <Button
                style={{ margin: 5 }}
                variant="contained"
                color="primary"
                onClick={() => {}}
              >
                unWatch
              </Button>
              <Button
                style={{ margin: 5 }}
                variant="contained"
                color="primary"
                onClick={() => {}}
              >
                clear
              </Button>
              <br />
              <br />
              <TextField
                label="Value"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                variant="outlined"
              />
              <br />
              <br />
              <Button
                style={{ margin: 5 }}
                variant="contained"
                color="primary"
                onClick={handleSet}
              >
                set
              </Button>
            </div>
          </Paper>
        </div>
      </Scrollbar>
    </Page>
  );
};

export default SupabaseRealtime;
